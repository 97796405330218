var cart = $('section.cart'),
    cart_add_timer;

function updateCart(){
    cart.addClass('loading');
    
    simpleAjax( "update_cart", function(res){
        var res = JSON.parse(res),
            cart_list = res.cart_list,
            cart_items = res.cart_items,
            cart_total = res.cart_total;

        $('.cart-list').html(cart_list);

        $('.cart-items .cart-item').remove();
        $('.cart-items').prepend(cart_items);

        $('.cwp-items .cwp-item').remove();

        $('.products-count').html(cart_total.quantity);
        $('.cart-total-price').html(cart_total.price_str);

        if(!cart_total.quantity){
            $('.cart .wrapper').remove();
        }

        cart.removeClass('loading');
    });
}
    
$(document).on('change', '.cart-quantity-input', function(){
    var elem = $(this);
    
    clearTimeout(cart_add_timer);
    cart_add_timer = setTimeout(function(){
        var id = elem.data('id'),
            quantity = elem.val(),
            result = {id, quantity};
    
        cart.addClass('loading');

        sendAjax( "update_cart_product", result, function(){
            updateCart();
        });
    }, 250);

});

$(document).on('click', '.remove-from-cart', function(){
    var elem = $(this),
        item = elem.parents('.cart-item'),
        id = elem.data('id');

    item.remove();
    cart.addClass('loading');

    sendAjax( "del_cart_product", id, function(){
        updateCart();
    });

});

$(document).on('click', '.cart-clean-btn', function(){
    cart.addClass('loading');
    
    simpleAjax( "clean_cart", function(){
        updateCart();
    });

});