$(document).on('click', '.add-to-cart', function(){
    var elem = $(this),
        id = elem.data('id'),
        quantity = 1,
        input = elem.parent().find('.ci-quantity-input'),
        data = {
            id,
            quantity
        }

    if(input.length){
        data = {
            id,
            quantity: input.val()
        }
    }

    elem.attr('disabled', true);

    sendAjax('add_to_cart', data, function(res){
        addNotif('Успех', 'Товар добавлен в корзину.', 'success');
        elem.attr('disabled', false);
        updateCart();
    },function(res){
        addNotif('Ошибка', 'Что-то пошло не так.', 'error');
        elem.attr('disabled', false);
    });

})