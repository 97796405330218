$('.menu-item-has-children').click(function(){
    var elem = $(this),
        menu = elem.find('.sub-menu');

    menu.fadeToggle(200);

    $(document).click(function (e){
		if (!elem.is(e.target) && elem.has(e.target).length === 0) {
			menu.fadeOut(200);
		}
	});

})
$('.menu-item-has-children > a').click(function(e){
    e.preventDefault();
});

$(document).on('click', '.toggle-advanced-menu', function(e){
    e.preventDefault();
    $('.advanced-menu').toggleClass('show');
});

$(document).click(function (e){
    var advanced_menu_parts = $('.advanced-menu, .toggle-advanced-menu');
    if (!advanced_menu_parts.is(e.target) && advanced_menu_parts.has(e.target).length === 0) {
        $('.advanced-menu').removeClass('show');
    }
});
