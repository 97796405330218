$('.services-slider').slick({
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    dots: false,
    responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        
    ]
});