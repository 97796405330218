$('#order_btn').click(function(){
    var elem = $(this),
        inputs = $('.order-form').find('.required');

    inputs.each(inputCheck);

    scrollTo($('.cart .error').eq(0));

    if(!$('.cart .error').length){
        elem.attr('disabled', true);
        cart.addClass('loading');

        var data = {
            user_name = $.trim($('#name').val() + ' ' + $('#surname').val()),
            phone = $.trim($('#phone').val()),
            email = $.trim($('#email').val()),
            address = $.trim($('#city').val() + ', ' + $('#address').val()),
            comment = $.trim($('#comment').val()),
            delivery = $('.select-method input:checked').val()
        };

        sendAjax('add_order', data, function(res){
            simpleAjax('clean_cart', function(){
                window.location.href = '/success';
            })
        },function(res){
            cart.removeCLass('loading');
            addNotif('Ошибка', 'Что-то пошло не так.', 'error');
            elem.attr('disabled', false);
        })
    }

});