function loadItems(elem, settings){
    elem.addClass('loading');
    settings = settings.split(' ');

    jQuery.post('/wp-admin/admin-ajax.php', {
        action: 'get_items_by_category',
        post_type: settings[0],
        taxonomy: settings[1],
        tag: settings[2],
    },
        function (res) {

            if(elem.hasClass('slick-initialized')){
                elem.slick('removeSlide', null, null, true);
                elem.slick('slickAdd',res);
                // elem.slick('setPosition');
            }else{
                elem.find('.catalog-item, .empty-block').remove();
                elem.append(res);
    
                if(elem.hasClass('items-slider')){
                    slider = elem.data('slider');
                    elem.slick(sliders[slider]);
                }
            }

            elem.removeClass('loading');

        }
    );
}

$('[data-load]').each(function(){
    var elem = $(this),
        settings = elem.data('load');

    loadItems(elem, settings);
})