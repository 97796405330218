function checkQuantity(value){
    value = value.replace(/[^0-9]/gm, '');
    value = Math.floor(parseInt(value));

    if(isNaN(value) || !value){
        value = 1;
    }

    if(value < 1){
        value = 1;
    }else if(value > 50){
        value = 50;
    }

    return value;
}

$(document).on('click', '.quantity-field button', function(){
    var elem = $(this),
        input = elem.siblings('input'),
        val = parseInt(input.val());

    if(elem.hasClass('plus')){
        input.val(val + 1);
    }else{
        input.val(val - 1);
    }

    input.change();
});

$(document).on('change', '.quantity-field input', function(){
    var elem = $(this),
        val = elem.val();

    elem.val(checkQuantity(val));
});