$('.product-images-slider').slick({
    arrows: true,
    dots: false,
    prevArrow: '<button class="icon-left prev"></button>',
    nextArrow: '<button class="icon-right next"></button>',
    asNavFor: '.product-images-nav'
});

$('.product-images-nav').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    asNavFor: '.product-images-slider',
    focusOnSelect: true
})