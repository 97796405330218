var animations = {
    'animateServices' : function(){
        var timer = 0,
            slides = $('.services-slider .slick-slide');

        slides.each(function(){
            var elem = $(this);

            setTimeout(() => {
                elem.addClass('animate');
            }, timer);

            timer += 200;

        });

        delete animations['animateServices'];
    },
    'animateBlog' : function(){
        var timer = 0,
            items = $('.blog-items .item-post');

        items.each(function(){
            var elem = $(this);

            setTimeout(() => {
                elem.addClass('animate');
            }, timer);

            timer += 200;

        });

        delete animations['animateBlog'];
    }
}

function checkScroll(){
    var sections = $('.animate-block'),
        scrolled = $(window).scrollTop();

    sections.each(function(){
        var elem = $(this),
            func = elem.data('func'),
            height = $(window).height() / 2;
            offset = elem.offset().top - height;

        if(offset <= scrolled){
            elem.addClass('animate');
            elem.removeClass('animate-block');
            
            if(animations[func]){
                animations[func]();
            }

        }
    })

};

$(document).ready(function () {    
    checkScroll();
    
    $(window).on('resize', checkScroll);
    $(document).on('scroll', checkScroll);
});