$(document).ready(function () {
    $('.main-title').addClass('loaded');
    


    setTimeout(function(){
        $('.grand-logo, .fixed-sidebar').addClass('loaded');
        $('.grand-logo').slideUp(1000);
        checkScroll();

        setTimeout(function(){
            checkScroll();
        }, 1000);

    },1000)
});

